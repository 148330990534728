import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"src\":[{\"path\":\"../assets/fonts/NostromoRegular-Black.otf\",\"weight\":\"900\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/NostromoRegular-Bold.otf\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/NostromoRegular-Heavy.otf\",\"weight\":\"800\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/NostromoRegular-Light.otf\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/NostromoRegular-Medium.otf\",\"weight\":\"500\",\"style\":\"normal\"}],\"variable\":\"--font-nostromo\"}],\"variableName\":\"nostromoRegular\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Share_Tech\",\"arguments\":[{\"weight\":\"400\",\"subsets\":[\"latin\"],\"variable\":\"--font-share-tech\"}],\"variableName\":\"shareTech\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/@rainbow-me/rainbowkit/dist/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Layout"] */ "/opt/build/repo/src/systems/core/components/Layout.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/systems/core/styles/globals.css");
