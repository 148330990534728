'use client';

import '@/core/styles/globals.css';
import '@rainbow-me/rainbowkit/styles.css';

import { WagmiConfig, createConfig, configureChains } from 'wagmi';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';

import { rainbowKitTheme } from '@/core/theme/rainbowKit';
import { ReactDevtools } from '@/core/components/ReactDevtools';
import { blastSepolia } from '@/utils/chains';

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [blastSepolia],
  [
    jsonRpcProvider({
      rpc: () => ({
        http: 'https://skilled-thrumming-seed.blast-sepolia.quiknode.pro/3f08d4301519a9edabe35ecdedae1174d6ca3ca8',
        webSocket:
          'wss://skilled-thrumming-seed.blast-sepolia.quiknode.pro/3f08d4301519a9edabe35ecdedae1174d6ca3ca8',
      }),
    }),
  ]
);
const { connectors } = getDefaultWallets({
  appName: 'My RainbowKit App',
  projectId: '8721494046917e310e3079b5fdee5728',
  chains,
});

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

export default function Provider({ children }: { children: React.ReactNode }) {
  return (
    <WagmiConfig config={wagmiConfig}>
      <ReactDevtools />
      <RainbowKitProvider chains={chains} theme={rainbowKitTheme} modalSize="compact">
        {children}
      </RainbowKitProvider>
    </WagmiConfig>
  );
}
