import { darkTheme, Theme } from '@rainbow-me/rainbowkit';
import merge from 'lodash.merge';

import { colors } from './colors';

export const rainbowKitTheme = merge(darkTheme(), {
  fonts: {
    body: 'var(--font-share-tech)',
  },
  colors: {
    accentColor: colors.red.DEFAULT,
    actionButtonBorder: colors.red.DEFAULT,
    actionButtonSecondaryBackground: '#000',
    modalBackground: '#000',
    closeButtonBackground: colors.red.DEFAULT,
    menuItemBackground: colors.red.DEFAULT,
    modalBorder: colors.red.DEFAULT,
    profileAction: colors.red.DEFAULT,
    profileActionHover: colors.red.DEFAULT,
    accentColorForeground: '#000',
  },
  radii: {
    actionButton: '0',
    connectButton: '0',
    menuButton: '0',
    modal: '4px',
    modalMobile: '4px',
  },
} as Theme);
