const colors = {
  black: '#000000',
  'bg-black': '#0D0D0D',
  'bg-gray': '#0B0B0B',
  white: '#ffffff',
  brokenWhite: '#E0E0E0',
  transparent: 'transparent',
  current: 'currentColor',
  blue: {
    300: '#58B7CF',
    DEFAULT: '#00cff0',
  },

  gray: {
    blue: '#3C3D46',
    600: '#737373',
    900: '#181124',
    950: '#101828',
    DEFAULT: '#887b7c',
    dark: '#18161B',
  },
  green: {
    DEFAULT: '#20D724',
  },
  red: {
    600: '#D50008',
    900: '#991D26',
    DEFAULT: '#CC2733',
  },
  purple: {
    dark: '#1d1131',
    light: '#433e4d',
    700: '#974DB1',
    900: '#181124',
    800: '#D50008',
  },
  cyan: {
    light: '#274D54',
    neutral: '#1B282A',
    DEFAULT: '#51D2E7',
  },
  lime: {
    DEFAULT: '#3AFF00',
  },
  yellow: {
    thirtiary: '#E8BB28',
    DEFAULT: '#E8BB27',
  },
  neutral: {
    50: '#FBFBFB',
    200: '#E4E1E1',
    300: '#D8D3D4',
    400: '#CCC6C7',
    500: '#B1A8A9',
    600: '#887B7C',
    700: '#5B5253',
    800: '#2D2929',
    900: '#141112',
    DEFAULT: '#2D2929',
  },
  primary: {
    200: '#E67C84',
    400: '#D8323E',
    500: '#CC2733',
    700: '#661419',
    DEFAULT: '#2D2929',
  },
  teal: {
    DEFAULT: '#18D9B0',
  },
  gradient: {
    chars: {
      1: '#030304',
      2: '#470004',
    },
  },
  warning: {
    DEFAULT: '#FF5C00',
  },
};

module.exports = {
  colors,
};
