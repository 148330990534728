'use client';

import Link from 'next/link';
import { SiDiscord, SiMedium, SiTwitter, SiYoutube } from 'react-icons/si';
import { IconType } from 'react-icons';
import { usePathname } from 'next/navigation';

import type { NavLink } from './Header';
import { Logo } from './Logo';

import { cx } from '../utils';
import { routes } from '../lib';

const DEFAULT_NAVIGATION: NavLink[] = [
  { href: 'https://degen-wars.gitbook.io/war-docs', label: 'Gitbook' },
  { href: routes.gameplay, label: 'How To Play' },
  { href: routes.gameMechanics, label: 'Game Mechanics' },
  // { href: routes.staking, label: 'Story' },
];

const SOCIAL_NAVIGATION: (NavLink & { icon: IconType })[] = [
  { href: 'https://discord.gg/degenwars', label: 'Discord', icon: SiDiscord },
  { href: 'https://twitter.com/Degen_Wars', label: 'Twitter', icon: SiTwitter },
  { href: 'https://medium.com/@degenwars', label: 'Medium', icon: SiMedium },
  {
    href: 'https://www.youtube.com/channel/UCjzTiXX5zW5VCOkhQ3PJ_iQ?sub_confirmation=1',
    label: 'YouTube',
    icon: SiYoutube,
  },
  // { href: 'https://github.com/degenwars', label: 'Github', icon: SiGithub },
  // { href: 'https://www.opensea.io/degenwars', label: 'OpenSea', icon: SiOpensea },
];

const PRIVACY_NAVIGATION: NavLink[] = [
  { href: `https://degenwars.gg/privacy.html`, label: 'Privacy Policy' },
  { href: `https://degenwars.gg/Terms-of-service.html`, label: 'Terms of Service' },
  // { href: 'https://t.me/degenwars', label: 'Cookie Settings' },
];

const GAME_NAVIGATION: NavLink[] = [
  { href: `https://degenwars.gg/privacy.html`, label: 'Privacy Policy' },
  { href: routes.gameplay, label: 'How To Play' },
  { href: `https://degenwars.gg/Terms-of-service.html`, label: 'Terms of Service' },
  { href: 'https://degen-wars.gitbook.io/war-docs', label: 'Gitbook' },
];

export function HomeFooter() {
  const pathname = usePathname();

  const isDecksPage = !!pathname?.includes(routes.game.decks);
  const isMintPage = !!pathname?.includes(routes.game.mint);
  const isGamePage = pathname === routes.game.root;
  const isLobbyPage = pathname === routes.game.lobby;
  const isLeaderboardPage = pathname === routes.game.leaderboard;
  const isDebugPage = pathname === routes.game.debug;
  const isStakingPage = pathname?.includes(routes.staking);
  const isRentingMarketPage = pathname?.includes(routes.rentingMarket.root);

  const shouldShowGameNav =
    isGamePage ||
    isLobbyPage ||
    isLeaderboardPage ||
    isDecksPage ||
    isMintPage ||
    isDebugPage ||
    isStakingPage ||
    isRentingMarketPage;

  const displayNavigation = shouldShowGameNav ? GAME_NAVIGATION : DEFAULT_NAVIGATION;

  return (
    <footer
      className={cx(
        'bg-black py-20',
        shouldShowGameNav
          ? 'lg:px-9 lg:py-7'
          : 'divide-y divide-solid divide-neutral-800 lg:px-28 lg:py-20'
      )}
    >
      <div
        className={cx(
          'flex flex-col items-center justify-between space-y-8',
          'lg:flex-row lg:space-y-0',
          shouldShowGameNav ? '' : 'pb-12 lg:pb-20'
        )}
      >
        <div
          className={cx(
            'flex flex-col items-center justify-between gap-y-8 sm:flex-row sm:gap-x-8'
          )}
        >
          {!shouldShowGameNav && (
            <Link className="" href="/">
              <Logo />
            </Link>
          )}

          <nav
            className="columns-2 sm:flex sm:items-center sm:justify-start sm:space-x-12 "
            aria-label="Footer"
          >
            {displayNavigation.map(({ href, label }) => (
              <div key={href} className="pb-6 sm:pb-0">
                <Link
                  key={href}
                  href={href}
                  className={cx(
                    'font-bold text-neutral-50',
                    'hover:text-neutral-50 ',
                    shouldShowGameNav ? 'text-sm' : 'text-base'
                  )}
                >
                  {label}
                </Link>
              </div>
            ))}
          </nav>
        </div>

        <div className={cx('flex items-center space-x-4')}>
          {SOCIAL_NAVIGATION.map(({ href, icon: Icon }) => (
            <Link
              key={href}
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold text-red"
            >
              <Icon className="inline h-6 w-6" />
            </Link>
          ))}
        </div>
      </div>

      {!shouldShowGameNav && (
        <div className={cx('mx-auto md:flex md:items-center md:justify-between lg:pt-8')}>
          <div className="flex justify-center space-x-6 md:order-2">
            {PRIVACY_NAVIGATION.map(({ href, label }) => (
              <Link key={href} href={href} className={cx('font-display text-sm text-neutral-400')}>
                {label}
              </Link>
            ))}
          </div>
          <div className="mr-4 mt-8 md:order-1 md:mt-0">
            <p className="font-display text-sm text-neutral-400">
              2024 Ⓒ Degen Wars. All Rights Reserved.
            </p>
          </div>
        </div>
      )}
    </footer>
  );
}
