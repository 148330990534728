'use client';

import * as React from 'react';
import Link from 'next/link';
import { Menu } from '@headlessui/react';
import { VscThreeBars } from 'react-icons/vsc';
import { HiOutlineSpeakerWave, HiOutlineSpeakerXMark, HiXMark } from 'react-icons/hi2';
import { usePathname } from 'next/navigation';
import { useIsClient } from 'usehooks-ts';

import { routes } from '@/core/lib';
import { cx } from '@/core/utils';
import { SnazzyLinkButton } from '@/ui';
import { useSettingsStore } from '@/play/store';

import { ConnectButton, NetworkWarningBanner } from './includes';

import { Logo } from '../Logo';

export type NavLink = {
  href: string;
  label: string;
};

export type HeaderProps = {
  navigation?: NavLink[] | undefined;
};

export const DEFAULT_NAVIGATION: NavLink[] = [
  { href: routes.gameplay, label: 'How to play' },
  { href: routes.gameMechanics, label: 'Game mechanics' },
  { href: routes.external.gitbook, label: 'Docs' },
  { href: routes.external.medium, label: 'News' },
];

const GAME_NAVIGATION: NavLink[] = [
  {
    href: routes.game.decks,
    label: 'Cards',
  },
  // {
  //   href: routes.staking,
  //   label: 'Staking',
  // },
  // {
  //   href: routes.rentingMarket.root,
  //   label: 'Renting Market',
  // },
  {
    href: routes.game.leaderboard,
    label: 'Ranks',
  },
  {
    href: routes.game.mint,
    label: 'Mint',
  },
];

export function Header(props: HeaderProps) {
  const { navigation = DEFAULT_NAVIGATION } = props;

  const pathname = usePathname();
  const { isMusic, isSfx, setIsMusic, setIsSfx } = useSettingsStore();
  const isClient = useIsClient();

  const isSoundOn = isMusic || isSfx;
  const isDecksPage = !!pathname?.includes(routes.game.decks);
  const isMintPage = !!pathname?.includes(routes.game.mint);
  const isGamePage = pathname === routes.game.root;
  const isLobbyPage = pathname === routes.game.lobby;
  const isLeaderboardPage = pathname === routes.game.leaderboard;
  const isDebugPage = pathname === routes.game.debug;
  const isStakingPage = pathname?.includes(routes.staking);
  const isRentingMarketPage = pathname?.includes(routes.rentingMarket.root);

  const shouldShowWalletConnect =
    isGamePage ||
    isLobbyPage ||
    isLeaderboardPage ||
    isDecksPage ||
    isMintPage ||
    isDebugPage ||
    isStakingPage ||
    isRentingMarketPage;

  const displayNavigation = shouldShowWalletConnect ? GAME_NAVIGATION : navigation;

  const toggleSound = () => {
    if (isSoundOn) {
      setIsMusic(false);
      setIsSfx(false);

      return;
    }

    setIsMusic(true);
    setIsSfx(true);
  };

  return (
    <header
      className={cx(
        'inset-x-0 top-0 z-20 bg-black px-5 py-3',
        'lg:bg-bg-black/50 lg:px-0 lg:py-5',
        shouldShowWalletConnect ? 'sticky border-b border-b-neutral-800' : 'fixed'
      )}
    >
      <div
        className={cx(
          'flex items-center justify-between',
          'lg:px-4',
          shouldShowWalletConnect ? 'fhd:px-10' : 'container mx-auto max-w-screen-2xl'
        )}
      >
        <Link href={routes.root}>
          <Logo className={cx('h-auto w-32')} />
        </Link>

        <div className={cx('hidden grow items-center justify-between gap-6', 'lg:flex')}>
          <div className={cx('ml-6 flex')}>
            {displayNavigation.map((nav) => {
              if (nav.href.startsWith('http')) {
                return (
                  <a
                    key={nav.href}
                    href={nav.href}
                    className={cx(
                      'px-4 py-2 text-sm text-brokenWhite',
                      'hover:text-yellow-thirtiary'
                    )}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {nav.label}
                  </a>
                );
              }

              return (
                <Link
                  key={nav.href}
                  href={nav.href}
                  className={cx(
                    'px-4 py-2 text-sm text-brokenWhite',
                    'hover:text-yellow-thirtiary'
                  )}
                >
                  {nav.label}
                </Link>
              );
            })}
          </div>

          {shouldShowWalletConnect ? (
            <div className={cx('flex flex-shrink-0 items-center gap-2', 'sm:gap-4')}>
              {isGamePage && (
                <button
                  className={cx('border-2 border-red p-2 text-red', 'hover:saturate-150')}
                  onClick={toggleSound}
                >
                  {isSoundOn ? (
                    <HiOutlineSpeakerWave className={cx('h-6 w-6')} />
                  ) : (
                    <HiOutlineSpeakerXMark className={cx('h-6 w-6')} />
                  )}
                </button>
              )}

              {isClient && <ConnectButton />}

              <Link
                href={isGamePage ? routes.game.lobby : routes.game.root}
                className={cx(
                  'bg-yellow-thirtiary px-4 py-3 text-[0.8125rem] font-extrabold text-black transition-all duration-300',
                  'hover:saturate-150'
                )}
              >
                {`Play Now`}
              </Link>
            </div>
          ) : (
            <Link
              key={''}
              href={routes.game.root}
              className={cx(
                'inline-flex items-center justify-center border border-yellow-thirtiary bg-yellow-thirtiary/30 px-3 py-2 text-sm font-bold leading-none text-yellow-thirtiary',
                'hover:saturate-150'
              )}
            >
              {`Play now`}
            </Link>
          )}
        </div>

        <Menu as="div" className={cx('lg:hidden')}>
          {({ close }) => (
            <>
              <Menu.Button className={cx('p-3')}>
                <VscThreeBars className={cx('h-6 w-6 text-yellow-thirtiary')} />
              </Menu.Button>

              <Menu.Items
                className={cx(
                  'fixed inset-0 z-50 flex h-full w-full flex-col justify-between bg-black'
                )}
              >
                <div className={cx('grow')}>
                  <div className={cx('flex items-center justify-between px-5 py-3')}>
                    <Link href={routes.root}>
                      <Logo className={cx('h-auto w-32')} />
                    </Link>

                    <button className={cx('bg-neutral-800 p-3')} onClick={() => close()}>
                      <HiXMark className={cx('h-6 w-6 text-yellow-thirtiary')} />
                    </button>
                  </div>

                  <div className={cx('flex flex-col')}>
                    {displayNavigation.map((nav) => (
                      <Menu.Item key={nav.href}>
                        {() => {
                          if (nav.href.startsWith('http')) {
                            return (
                              <a
                                href={nav.href}
                                className={cx(
                                  'p-3 text-h5 text-brokenWhite',
                                  'hover:text-yellow-thirtiary'
                                )}
                                target="_blank"
                                rel="noreferrer noopener"
                                onClick={() => close()}
                              >
                                {nav.label}
                              </a>
                            );
                          }

                          return (
                            <Link
                              href={nav.href}
                              className={cx(
                                'p-3 text-h5 text-brokenWhite',
                                'hover:text-yellow-thirtiary'
                              )}
                              onClick={() => close()}
                            >
                              {nav.label}
                            </Link>
                          );
                        }}
                      </Menu.Item>
                    ))}
                  </div>
                </div>

                <div className={cx('flex flex-col px-5 py-8')}>
                  <SnazzyLinkButton href={routes.external.discord} isExternal>
                    {`Join Discord`}
                  </SnazzyLinkButton>
                </div>
              </Menu.Items>
            </>
          )}
        </Menu>
      </div>

      <NetworkWarningBanner />
    </header>
  );
}
