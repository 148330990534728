'use client';

import * as React from 'react';
import { usePathname } from 'next/navigation';

import { HomeFooter } from './HomeFooter';
import { Header, HeaderProps } from './Header';

import { cx } from '../utils';
import { routes } from '../lib';

type LayoutProps = React.PropsWithChildren<{
  hasFooter?: boolean;
  hasHeader?: boolean;
  navigation?: HeaderProps['navigation'];
  className?: string;
}>;

export function Layout(props: LayoutProps) {
  const { navigation, children, hasHeader = false, hasFooter = false, className } = props;

  const pathname = usePathname();

  const isPlayPage = pathname === routes.game.play;

  return (
    <div className={cx('flex grow flex-col', className)}>
      {!isPlayPage && hasHeader && <Header navigation={navigation} />}

      {children}

      {!isPlayPage && hasFooter && <HomeFooter />}
    </div>
  );
}
